import React from 'react';
import { lock } from '../../../actions/authActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../images/small-black-.png';
import './adminLayout.css';

class AdminLayout extends React.Component {
  checkPermissions(level) {
    var str = 'permission-check-';
    switch (level) {
      case 1:
        str += 'root';
        break;
      case 2:
        str += 'super-admin';
        break;
      case 3:
        str += 'admin';
        break;
      case 4:
        str += 'manager';
        break;
      default:
        str += 'none';
    }
    return str;
  }
  render() {
    var permissionClass = 'bootstrap-iso ' + this.checkPermissions(this.props.empPermission);
    return (
      <div className={permissionClass}>
        <div className="admin-page">
          <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div
              className="navbar-brand"
              style={{
                position: 'relative',
                textAlign: 'center',
                width: '127px'
              }}
              onClick={() => {
                this.props.history.push('/admin');
              }}
            >
              <img src={logo} height="100%" style={{ padding: '7%' }} alt="logo" />
              <div className="navbar-img-text" style={{ cursor: 'pointer' }}>
                Home
              </div>
            </div>
            <button
              className="navbar-toggler no-select collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar" />
              <span className="icon-bar middle-bar" />
              <span className="icon-bar bottom-bar" />
              <span className="sr-only">Toggle navigation</span>
            </button>
            <div className="collapse navbar-collapse bg-white" id="navbarNav">
              <ul className="navbar-nav navbar-custom">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}
                  >
                    People
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-item">
                      <Link to="/admin/users" className="nav-link">
                        Users
                      </Link>
                    </div>
                    <div className="dropdown-item permission-super-admin">
                      <Link to="/admin/employees" className="nav-link">
                        Employees
                      </Link>
                    </div>
                    <div className="dropdown-item permission-super-admin">
                      <Link to="/admin/systemUsers" className="nav-link">
                        System Logins
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}
                  >
                    Inventory
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-item">
                      <Link to="/admin/inventory" className="nav-link">
                        Inventory Items
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/inventoryType" className="nav-link">
                        Inventory Types
                      </Link>
                    </div>
                    {/* <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Something else here</a> */}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}
                  >
                    Purchases
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="dropdown-item">
                      <Link to="/admin/access" className="nav-link">
                        Access
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/memberships" className="nav-link">
                        Memberships
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/skatePunches" className="nav-link">
                        Skate Punches
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/purchasehistory" className="nav-link">
                        Purchase History
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link to="/admin/coffeeShop" className="nav-link">
                    Coffee Shop
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link to="/admin/events" className="nav-link">
                    Events
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link to="/admin/programs" className="nav-link">
                    Programs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/activities" className="nav-link">
                    Activities
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ cursor: 'pointer' }}
                  >
                    Activity Options
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-item">
                      <Link to="/admin/cities" className="nav-link">
                        Cities
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/sites" className="nav-link">
                        Sites
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/partners" className="nav-link">
                        Partners
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/providers" className="nav-link">
                        Providers
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/admin/activitydetails" className="nav-link">
                        Activity Details
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="/admin/scanners" className="nav-link">
                    Scanners
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/News" className="nav-link">
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/reporting" className="nav-link">
                    Reporting
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/admin"
                    className="nav-link"
                    onClick={() => {
                      this.props.lock();
                    }}
                  >
                    Lock
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <>{this.props.children}</>
        </div>
      </div>
    );
  }
}

export default connect(null, { lock })(AdminLayout);
